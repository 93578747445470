:root {
  --spaceAfterHeader: 120px;
}
@media (max-width: 1023px) {
  :root {
    --spaceAfterHeader: 80px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceAfterHeader: 40px;
  }
}
:root {
  --deskWidth: 1540px;
  --deskSpace: 80px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1439px) {
  :root {
    --deskSpace: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 24px;
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
.button:hover,
.button:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  .button {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2024 MyFonts Inc. */
@font-face {
  font-family: "MuseoSans";
  font-weight: 100;
  src: url('/extras/fonts/MuseoSans100/font.woff2') format('woff2'), url('/extras/fonts/MuseoSans100/font.woff') format('woff');
}
@font-face {
  font-family: "MuseoSans";
  font-weight: 500;
  src: url('/extras/fonts/MuseoSans500/font.woff2') format('woff2'), url('/extras/fonts/MuseoSans500/font.woff') format('woff');
}
@font-face {
  font-family: "MuseoSans";
  font-weight: 900;
  src: url('/extras/fonts/MuseoSans900/font.woff2') format('woff2'), url('/extras/fonts/MuseoSans900/font.woff') format('woff');
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #d41b16;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 500;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 24px;
  font-family: 'MuseoSans', sans-serif;
  line-height: 1.25;
}
.unit caption {
  display: none;
}
.flag {
  background: #d41b16;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 30px;
  position: relative;
  margin-top: 20px;
}
.list--bullet li:first-child {
  margin-top: 0;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 1439px) {
  .list--bullet li {
    padding-left: 24px;
  }
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 36.484375%;
  position: relative;
}
@media (max-width: 1920px) {
  #slides {
    padding-bottom: 700px;
  }
}
@media (max-width: 1439px) {
  #slides {
    padding-bottom: 560px;
  }
}
@media (max-width: 1023px) {
  #slides {
    padding-bottom: 400px;
  }
}
@media (max-width: 767px) {
  #slides {
    padding-bottom: 320px;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #d41b16;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  display: none;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 60px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 1023px) {
  .cb-index-all {
    bottom: 30px;
  }
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 12px;
  height: 12px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #b3b3b3;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  display: none;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'MuseoSans', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #d41b16;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #d41b16;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'MuseoSans', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.unit.form .part.must {
  font-size: 16px;
  line-height: 1.25;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #f2f2f2;
  min-height: 60px;
  padding: 5px 10px;
  -webkit-appearance: none;
  font-weight: 100;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
@media (max-width: 1439px) {
  .unit.form input.text,
  .unit.form textarea {
    font-size: 18px;
    line-height: 1.33333333;
  }
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 30px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 15px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'MuseoSans', sans-serif;
  font-size: 24px;
  line-height: 1.25;
  background: #f2f2f2;
  height: 60px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  .unit.form input.submit {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  .two-step-verification-container a {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 500;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 500;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 3.12907432vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 80px;
  margin-top: 50px;
  transition: all 0.4s;
}
.cb-scroll-triggered--active #home {
  height: 50px;
  margin-top: 20px;
}
@media (max-width: 1023px) {
  #home {
    height: 50px !important;
    margin: 30px 0 !important;
  }
}
@media (max-width: 767px) {
  #home {
    height: 40px !important;
    margin: 20px 0 !important;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: var(--spaceAfterHeader);
  margin-bottom: var(--spaceTotal);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'MuseoSans', sans-serif;
  font-size: 24px;
  line-height: 1.25;
  color: #000;
  overflow: hidden;
  hyphens: none;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 248px;
}
@media (max-width: 1023px) {
  .wrapper {
    padding-top: 114px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding-top: 84px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  border-bottom: 4px solid #d41b16;
}
.section--marginTop {
  margin-top: auto;
}
.section--footer {
  background-color: #d41b16;
  color: #fff;
  margin-top: calc(var(--spaceAfterHeader) - var(--spaceTotal));
}
.section--footer a {
  color: #fff;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #000;
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .section--header .content {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
.subcontent {
  float: left;
  width: 100%;
}
.headerlinks {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .headerlinks {
    display: none;
  }
}
#services {
  float: left;
}
#services .meta {
  float: left;
  margin-right: 36px;
  font-size: 20px;
  line-height: 1.2;
  color: #d41b16;
}
#services .meta:hover,
#services .meta:focus {
  color: #000;
}
.search {
  float: left;
  position: relative;
}
.search__input {
  float: left;
  background-color: #f2f2f2;
  height: 60px;
  width: 324px;
  max-width: 100%;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 20px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 100;
}
.search__submit {
  position: absolute;
  left: 0;
  width: 60px;
  height: 100%;
  z-index: 2;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  cursor: pointer;
  background-size: 32px 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-red.svg);
}
.navigation {
  float: left;
  width: 100%;
  margin-top: 60px;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .navigation {
  margin-top: 30px;
}
@media (max-width: 1023px) {
  .navigation {
    display: none;
  }
}
.cbdModule--moodTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%) translateX(-50%);
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  text-align: center;
  font-size: 80px;
  line-height: 1.125;
  color: #fff;
  font-weight: 900;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 16px;
  line-height: 1.5;
  margin: 30px 0;
}
@media (max-width: 767px) {
  .footcontent {
    flex-direction: column;
    align-items: flex-start;
  }
}
#legal {
  float: left;
}
a {
  color: #d41b16;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #000;
}
h1 {
  font-size: 40px;
  line-height: 1.4;
  font-weight: 100;
  color: #d41b16;
}
h2 {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 100;
}
h3 {
  font-size: 25px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 500;
  color: #d41b16;
}
.area--oneB .unitTwo,
.area--two .unitTwo {
  background-color: #b3b3b3;
  box-sizing: border-box;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding: calc(20px - var(--spacePart)) 20px;
  color: #fff;
}
.area--oneB .unitTwo .part.text.tall:first-child,
.area--two .unitTwo .part.text.tall:first-child {
  width: calc(100% + 40px);
  margin-left: -20px;
  background-color: #d41b16;
  box-sizing: border-box;
  padding: 20px;
  margin-top: calc((20px - var(--spacePart)) * -1);
  margin-bottom: calc(20px - var(--spacePart));
  border-bottom: 5px solid #fff;
}
.area--oneB .unitTwo .part.line,
.area--two .unitTwo .part.line {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.area--oneB .unitTwo .part.line hr,
.area--two .unitTwo .part.line hr {
  border-width: 5px;
  border-color: #fff;
}
.area--oneB .unitThree,
.area--two .unitThree {
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding: calc(20px - var(--spacePart)) 20px;
}
.area--oneB .unitThree + .unitThree,
.area--two .unitThree + .unitThree {
  margin-top: calc((var(--spaceTotal) - 16px) * -1);
}
.area--oneB .unitFour,
.area--two .unitFour {
  border: 1px solid #d41b16;
  box-sizing: border-box;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding: calc(20px - var(--spacePart)) 20px;
}
div.link .open {
  display: inline-block;
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
div.link .open:hover,
div.link .open:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  div.link .open {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
div.load .load {
  float: left;
  color: inherit;
  padding-left: 30px;
  padding-right: 10px;
  background-size: auto 22px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-load-red.svg);
  transition: all 0.2s;
}
div.load .load:hover,
div.load .load:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  div.load .load {
    background-position: 0 1px;
  }
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  float: left;
  position: relative;
  width: 30px;
  height: 20px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 4px;
  background-color: #d41b16;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
}
.mobilelinks {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}
.mobilelinks .service_kontakt {
  margin-right: 0;
  margin-left: 36px;
}
@media (max-width: 767px) {
  .mobilelinks {
    margin: 60px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .mobilelinks .service_kontakt {
    margin-left: 0;
    margin-top: 20px;
  }
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #d41b16;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin-top: 180px;
}
.mobile-navigation div.sub1 > .item {
  margin-top: 40px;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  padding-left: 56px;
  font-size: 30px;
  line-height: 1.33333333;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 {
    margin-top: 140px;
  }
  .mobile-navigation div.sub1 > .item > .menu {
    font-size: 26px;
    line-height: 1.30769231;
    padding-left: 46px;
  }
}
.mobile-navigation div.sub2 {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 56px;
  height: 0;
  overflow: hidden;
  transition: all 0.4s;
}
.mobile-navigation div.sub2 > .item {
  margin-top: 14px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 30px;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 22px;
  line-height: 1.27272727;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub2 {
    padding-left: 46px;
  }
  .mobile-navigation div.sub2 > .item > .menu {
    font-size: 20px;
    line-height: 1.2;
  }
}
.mobile-navigation div.sub1 > .item.cb-toggle-target-active div.sub2 {
  height: var(--js-elementHeight);
}
.mobile-navigation div.sub1 > .item.cb-toggle-target-active div.sub2 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s 0.1s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-size: auto 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-red.svg);
  transition: all 0.4s;
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
@media (max-width: 767px) {
  .mobile-navigation .cb-toggle {
    width: 34px;
    height: 34px;
  }
}
.part.auth form {
  float: left;
  width: 100%;
}
.part.auth .body,
.part.auth .foot {
  width: 100%;
  margin: 0;
}
.part.auth fieldset {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part.auth fieldset:first-child {
  margin-top: 0;
}
.part.auth label.name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .part.auth label.name {
    width: 30%;
    min-height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.part.auth input.text,
.part.auth input.password {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #f2f2f2;
  min-height: 60px;
  padding: 5px 10px;
}
.part.auth input.text.fail,
.part.auth input.password.fail {
  border: 2px solid #ae2121;
}
.part.auth input.text.fade,
.part.auth input.password.fade {
  opacity: 0.3;
}
@media (min-width: 768px) {
  .part.auth input.text,
  .part.auth input.password {
    float: right;
    width: 66%;
  }
}
.auth-login-button {
  float: right;
  margin-top: var(--spacePart);
}
.part.auth input.submit {
  float: left;
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
.part.auth input.submit:hover,
.part.auth input.submit:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  .part.auth input.submit {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
@media (max-width: 1439px) {
  .wrapper {
    font-size: 18px;
    line-height: 1.33333333;
  }
  .cbdModule--moodTitle {
    font-size: 60px;
    line-height: 1.16666667;
  }
  h1 {
    font-size: 30px;
    line-height: 1.33333333;
  }
  h2 {
    font-size: 24px;
    line-height: 1.25;
  }
  h3 {
    font-size: 20px;
    line-height: 1.3;
  }
}
@media (max-width: 1023px) {
  .cbdModule--moodTitle {
    font-size: 40px;
    line-height: 1.25;
  }
}
@media (max-width: 767px) {
  .cbdModule--moodTitle {
    font-size: 30px;
    line-height: 1.2;
  }
  h1 {
    font-size: 26px;
    line-height: 1.23076923;
  }
}
/*# sourceMappingURL=./screen-small.css.map */